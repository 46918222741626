/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-slash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 00-2 2v8.01A2 2 0 002 14h5.5a.5.5 0 000-1H2a1 1 0 01-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 001 0V4a2 2 0 00-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 011-1h12a1 1 0 011 1v.217l-7 4.2z"/><path pid="1" d="M14.975 10.025a3.5 3.5 0 10-4.95 4.95 3.5 3.5 0 004.95-4.95m-4.243.707a2.5 2.5 0 013.147-.318l-3.465 3.465a2.5 2.5 0 01.318-3.147m.39 3.854l3.464-3.465a2.501 2.501 0 01-3.465 3.465z"/>',
    },
});
